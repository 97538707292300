<template>
  <div class="questionnaire-content">
    <template v-if="complete">
      <div style="font-size: 200px">已完成</div>
    </template>
    <template v-if="undone">
      <div class="title" style="margin-top: 0">
        {{current && current.name}}
      </div>
      <div class="survey-content">
        <div style="position: absolute; width: 100%; height: 100%;">
          <img v-for="item in srcArr" id="pdf" :key="item" :src="item"/>
        </div>
      </div>
      <div class="title">看完这段对话后，请您在文本框中回答以下4个问题。</div>
      <div class="comment-content" v-if="show">
        <el-form :disabled="disabled"
                 :model="commentForm" :rules="rules" ref="commentForm">
          <template v-for="(value, key, index) in commentForm">
            <el-form-item :label="`问题${index + 1}: ${questions[key]}`" :prop="key" :key="key">
              <el-input type="textarea" :autosize="{ minRows: 8 }" v-model="commentForm[key]"
                        style="width: 100%"></el-input>
            </el-form-item>
          </template>
          <el-form-item v-if="!disabled">
            <el-button type="primary" :load="loading" @click="submitFn">提交</el-button>
            <el-button @click="clearFn">清空</el-button>
          </el-form-item>
        </el-form>
        <el-button type="primary" @click="index -= 1" :disabled="index === 0">上一题</el-button>
        <el-button type="primary" @click="index += 1" :disabled="(index + 1) === showList.length">
          下一题</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import research from '@/api/research';
import comments from '@/api/comments';

export default {
  props: {
    category: String,
  },
  data() {
    return {
      srcArr: [],
      show: false,
      loading: false,
      disabled: false,
      flag: false,
      complete: false,
      undone: false,
      index: 0,
      current: null,
      showList: [],
      questions: {},
      commentForm: {
        question1: '',
        question2: '',
        question3: '',
        question4: '',
      },
      rules: {},
    };
  },
  watch: {
    $route: {
      handler() {
        this.$nextTick(() => {
          Object.assign(this.$data, this.$options.data());
          this.completeFn();
        });
      },
    },
    current: {
      deep: true,
      handler(val) {
        if (!val) return;
        const { url, iscommit } = val;
        this.$nextTick(() => { this.setForm(); });
        // this.srcArr = url.split(',').map((item) => `http://47.94.225.94${item}`);
        this.srcArr = url.split(',').map((item) => `${window.location.origin}${item}`);
        this.disabled = iscommit === 1;
      },
    },
    index(val) {
      this.clearFn();
      this.current = this.showList[val];
    },
  },
  mounted() {
    this.completeFn();
  },
  methods: {
    initSearchFn() {
      const arr = [
        research.show({ category: this.category }),
        comments.question(),
      ];
      Promise.all(arr).then(([{ data: showList }, { data: questions }]) => {
        if (showList && showList.length) {
          this.show = true;
          this.showList = showList;
          this.questions = questions;
          [this.current] = showList;
        } else this.$message.info('该用户尚未添加问卷调查');
      }).catch((error) => {
        this.$message.error(error.message);
      });
    },
    completeFn() {
      research.complete({
        category: this.category,
      }).then(({ data }) => {
        // 注释原来的，会显示已完成
        // this.complete = data;
        // this.undone = !data;
        // if (!data && !this.flag) {
        //   this.flag = true;
        //   this.initSearchFn();
        // }

        // 不会显示已完成
        // this.complete = !data;
        this.undone = data;
        console.log(data);
        if (!this.flag) {
          this.flag = true;
          this.initSearchFn();
        }
      });
    },
    setForm() {
      const { answer } = this.current;
      Object.keys(this.commentForm).forEach((key) => {
        if (answer) this.commentForm[key] = answer[key];
        this.rules[key] = [{
          required: true,
          message: `请填写问题: ${this.questions[key]}`,
          trigger: 'blur',
        }];
      });
    },
    submitFn() {
      this.$refs.commentForm.validate((valid) => {
        if (valid) {
          const { paperid } = this.current;
          this.loading = true;
          research.add({
            paperId: paperid,
            answer: this.commentForm,
          }).then(() => {
            this.loading = false;
            Object.assign(this.current, { answer: { ...this.commentForm }, iscommit: 1 });
            this.completeFn();
          }).catch(() => {
            this.loading = false;
          });
        }
      });
    },
    clearFn() {
      this.$refs.commentForm.resetFields();
    },
  },
};
</script>

<style scoped lang="less">
  .questionnaire-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .title {
      width: 100%;
      line-height: 36px;
      border-bottom: 1px solid #DCDFE6;
      margin: 22px 0;
    }
    .survey-content {
      width: 100%;
      height: 0;
      padding-bottom: 42.857%;
      background: #5a5a5a;
      overflow: auto;
      position: relative;
      #pdf {
        position: relative;
        width: 100%;
      }
    }
    .comment-content {
      width: 100%;
    }
  }
</style>
